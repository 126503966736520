import Button from "components/Button/Button";
import styles from "./actionSection.module.scss";
import { DropOffForm } from "types/logistic.types";
import { useState } from "react";
import { ModalContainer } from "components/modals/ModalContainer";
import { Edit } from "@mui/icons-material";
import DropOffContactModal from "./DropOffContactModal/DropOffContactModal";
import ValidateDropOffModal from "./ValidateDropOffModal/ValidateDropOffModal";
import ValidateFormModal from "./ValidateFormModal/ValidateFormModal";
import { useMutation } from "react-query";
import { sendEstimateFromDropOff } from "requests/dropOff";
import { toast } from "react-toastify";
import { STD_ERROR_MESSAGE } from "utils/constants";
import { TOAST_ERROR_OPTIONS } from "utils/toast.options";
import { useNavigate } from "react-router-dom";
import { INITIAL_DROPOFF } from "utils/logistic.init";

interface ActionSectionProps {
  dropOff: DropOffForm;
  onDropOffChange: (dropOff: DropOffForm) => void;
}

export default function ActionSection({
  dropOff,
  onDropOffChange,
}: ActionSectionProps) {
  const navigate = useNavigate();
  const mutation = useMutation(sendEstimateFromDropOff, {
    onSuccess: ({ trackId }) => {
      navigate(`/overview/${trackId}`);
    },
    onError: () => {
      toast.error(STD_ERROR_MESSAGE, TOAST_ERROR_OPTIONS);
    },
  });
  const [openValidateDropOffModal, setOpenValidateDropOffModal] =
    useState<boolean>(false);
  const [openContactModal, setOpenContactModal] = useState<boolean>(false);
  const [openValidateFormModal, setOpenValidateFormModal] =
    useState<boolean>(false);

  return (
    <div className={styles["action-container"]}>
      <div className={styles["action-container-left"]}>
        {dropOff.contact ? (
          <div
            className={styles["contact-information"]}
            onClick={handleOpenContactModal}
          >
            <p className={styles["contact-item"]}>
              {`${dropOff.contact.displayname} | ${dropOff.contact.phone} | ${dropOff.contact.email}`}
            </p>
            <div className={styles["edit-button"]}>
              <Edit className={styles["icon"]} />
            </div>
          </div>
        ) : (
          <Button
            className={styles["action-button"]}
            onClick={handleOpenContactModal}
          >
            Ajouter un contact
          </Button>
        )}
      </div>
      <div className={styles["action-container-right"]}>
        <Button
          isLoading={mutation.isLoading}
          className={styles["action-button"]}
          onClick={handleSendEstimate}
        >
          Envoyer une estimation
        </Button>
        <Button
          className={styles["action-button"]}
          onClick={handleOpenValidateDropOffModal}
        >
          Valider les articles
        </Button>
      </div>
      <ModalContainer
        isOpen={openContactModal}
        onCancel={handleCloseContactModal}
      >
        <DropOffContactModal
          dropOff={dropOff}
          onDropOffChange={onDropOffChange}
          onClose={handleCloseContactModal}
        />
      </ModalContainer>
      <ModalContainer
        isOpen={openValidateDropOffModal}
        onCancel={handleCloseValidateDropOffModal}
      >
        <ValidateDropOffModal
          dropOff={dropOff}
          onDropOffChange={onDropOffChange}
          onClose={handleCloseValidateDropOffModal}
        />
      </ModalContainer>
      <ModalContainer
        isOpen={openValidateFormModal}
        onCancel={handleCloseValidateFormModal}
        width="narrow"
        height="fit"
      >
        <ValidateFormModal dropOff={dropOff} />
      </ModalContainer>
    </div>
  );

  function handleOpenContactModal() {
    setOpenContactModal(true);
  }

  function handleCloseContactModal() {
    setOpenContactModal(false);
  }

  function handleCloseValidateFormModal() {
    setOpenValidateFormModal(false);
  }

  function handleOpenValidateDropOffModal() {
    if (!validateDropOffForm()) {
      return setOpenValidateFormModal(true);
    }
    setOpenValidateDropOffModal(true);
  }

  function handleSendEstimate() {
    if (!validateEstimateDropOffForm()) {
      return setOpenValidateFormModal(true);
    }
    mutation.mutate({ dropOff });
    onDropOffChange(INITIAL_DROPOFF);
  }

  function handleCloseValidateDropOffModal() {
    setOpenValidateDropOffModal(false);
  }

  function validateEstimateDropOffForm() {
    if (!dropOff.contact) {
      return false;
    }
    if (!dropOff.productList.length) {
      return false;
    }
    if (
      dropOff.productList.some(
        (p) => !p.productGroup || !p.operationGroup || p.operations.length < 1
      )
    ) {
      return false;
    }
    return true;
  }

  function validateDropOffForm() {
    if (!dropOff.contact) {
      return false;
    }
    if (!dropOff.repareDays) {
      return false;
    }
    if (!dropOff.timeSlot) {
      return false;
    }
    if (
      dropOff.productList.some(
        (p) =>
          !p.productGroup ||
          !p.operationGroup ||
          !p.workshopId ||
          p.operations.length < 1
      )
    ) {
      return false;
    }
    if (!dropOff.productList.length && !dropOff.crossSells.length) {
      return false;
    }
    return true;
  }
}
