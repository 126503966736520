import styles from "./checkboxItem.module.scss";

interface CheckboxItemProps {
  className?: string;
  checked: boolean;
  setChecked: (checked: boolean) => void;
  title: string;
}

export default function CheckboxItem({
  className,
  checked,
  setChecked,
  title,
}: CheckboxItemProps) {
  return (
    <div className={`${styles["checkbox-item"]} ${className ? className : ""}`}>
      <input
        id={title}
        type="checkbox"
        onChange={() => setChecked(!checked)}
        checked={checked}
      />
      <label className={styles["label"]} htmlFor={`${title}`}>
        {title}
      </label>
    </div>
  );
}
