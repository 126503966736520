import { useQuery } from "react-query";
import { fetchQuote } from "requests/quote";
import styles from "./quoteSummaryModal.module.scss";
import OverviewSection from "pages/overview/read/_components/OverviewSection/OverviewSection";
import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";
import DataError from "components/errors/DataError/DataError";
import QuoteProduct from "pages/overview/read/_components/QuoteSection/QuoteProduct/QuoteProduct";
import QuoteSumUp from "pages/overview/read/_components/QuoteSection/QuoteSumUp/QuoteSumUp";
import translate from "utils/translation";

interface QuoteSummaryModalProps {
  quoteId: string;
}

export default function QuoteSummaryModal({ quoteId }: QuoteSummaryModalProps) {
  const { data, isLoading } = useQuery(
    ["quote", quoteId],
    () => fetchQuote(quoteId),
    {
      refetchOnWindowFocus: false,
      enabled: Boolean(quoteId),
    }
  );

  if (!quoteId) {
    return <></>;
  }

  if (isLoading) {
    return (
      <div className={styles["quote-summary-modal"]}>
        <PictureLoadingSpinner />
      </div>
    );
  }

  if (!data) {
    <div className={styles["quote-summary-modal"]}>
      <DataError error={"quote"} />
    </div>;
  }

  return (
    <div className={styles["quote-summary-modal"]}>
      <div className={styles["quote-summary-modal-container"]}>
        <ul className={styles["product-list"]}>
          {data?.productsList?.map((product, i) => (
            <QuoteProduct product={product} key={i} />
          ))}
        </ul>
        <QuoteSumUp
          className={styles["sub-total"]}
          title={"Sous-total"}
          value={data?.operationsTotal || 0}
        />
        <QuoteSumUp
          className={styles["sub-total"]}
          title={`Livraison (${translate(data?.shipping?.shippingService)})`}
          value={data?.shipping?.price || 0}
        />
        {!!data?.discountTotal && (
          <QuoteSumUp
            title={"Code promo"}
            value={-data?.discountTotal}
            className={styles["sub-total"]}
          />
        )}
        <QuoteSumUp
          title={"Total"}
          value={data?.total || 0}
          className={styles["total"]}
        />
      </div>
    </div>
  );
}
