import SectionTitle from "components/SectionTitle/SectionTitle";
import styles from "./workshopIdSection.module.scss";
import Input from "components/inputs/Input/Input";
import { DropOffProductForm } from "types/logistic.types";
import { useMutation } from "react-query";
import { checkIfWorkshopIdExists } from "requests/logistic";
import { useState } from "react";
import { useDebounce } from "hooks/useDebounce";
import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";
import { Check } from "@mui/icons-material";

interface WorkshopIdSectionProps {
  product: DropOffProductForm | null;
  onWorkshopIdChange: (workshopId: string | null) => void;
}

export default function WorkshopIdSection({
  product,
  onWorkshopIdChange,
}: WorkshopIdSectionProps) {
  const [workshopId, setWorkshopId] = useState<string>("");
  const [workshopIdIsValid, setWorkshopIdIsValid] = useState<boolean>(false);
  const mutation = useMutation(checkIfWorkshopIdExists, {
    onSuccess: (data) => {
      if (workshopId.length === 7) {
        setWorkshopIdIsValid(data);
        if (data) {
          onWorkshopIdChange(workshopId);
        } else {
          onWorkshopIdChange(null);
        }
      } else {
        onWorkshopIdChange(null);
      }
    },
    onError: () => {
      setWorkshopIdIsValid(false);
    },
  });
  useDebounce(() => mutation.mutate(workshopId), [workshopId]);

  if (!product || !product.operationGroup || !product.productGroup) {
    return null;
  }

  return (
    <>
      <SectionTitle
        title={"Ticket atelier"}
        className={styles["section-title"]}
      />
      <div className={styles["workshop-id-section"]}>
        <Input
          onChange={handleOnChange}
          onFocus={AddPrefixTicket}
          value={workshopId}
          placeholder={"1-1-XXX"}
          inputMode="numeric"
        />
        <div className={styles["workshop-exists"]}>
          {mutation.isLoading ? (
            <PictureLoadingSpinner className={styles["loading-spinner"]} />
          ) : (
            <>
              {workshopIdIsValid ? (
                <Check className={styles["valid"]} />
              ) : (
                <p className={styles["error-message"]}>
                  {workshopId && workshopId.length === 7
                    ? "ce numéro de ticket est incorrect ou déjà utilisé."
                    : "1-1-XXX"}
                </p>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );

  function handleOnChange({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    setWorkshopIdIsValid(false);
    setWorkshopId(target.value);
  }

  function AddPrefixTicket({
    target,
  }: {
    target: EventTarget & HTMLInputElement;
  }) {
    if (!target.value.startsWith("1-1-") && !target.value) {
      setWorkshopId("1-1-" + target.value);
    }
  }
}
