import styles from "./modalContainer.module.scss";
import { Close } from "@mui/icons-material";
import ModalPortal from "./ModalPortal";
import RemoveBodyScroll from "./RemoveBodyScroll";
import clsx from "clsx";

interface ModalContainerProps {
  children: React.ReactNode;
  onCancel?: () => void;
  isOpen: boolean;
  width?: "wide" | "normal" | "narrow";
  height?: "normal" | "fit";
}

export function ModalContainer({
  children,
  onCancel,
  isOpen,
  width = "normal",
  height = "normal",
}: ModalContainerProps) {
  return (
    <ModalPortal isOpen={isOpen}>
      <RemoveBodyScroll>
        <div className={styles["modal-container"]}>
          <div
            className={clsx(styles["close-button-container"], widthDispatch())}
          >
            <div className={styles["close-button"]} onClick={onCancel}>
              <Close />
            </div>
          </div>
          <div
            className={clsx(
              styles["modal-container-children"],
              widthDispatch(),
              heightDispatch()
            )}
          >
            {children}
          </div>
          <div className={styles["background"]} onClick={onCancel}></div>
        </div>
      </RemoveBodyScroll>
    </ModalPortal>
  );

  function widthDispatch() {
    switch (width) {
      case "wide":
        return styles["wide-width"];
      case "normal":
        return styles["normal-width"];
      case "narrow":
        return styles["narrow-width"];
      default:
        return styles["normal-width"];
    }
  }

  function heightDispatch() {
    switch (height) {
      case "fit":
        return styles["fit-height"];
      case "normal":
        return styles["normal-height"];
      default:
        return styles["normal-height"];
    }
  }
}
