import styles from "./productGroupSelect.module.scss";
import clsx from "clsx";
import { PRODUCT_GROUP } from "../../../../../../utils/logistic.init";
import translate from "../../../../../../utils/translation";
import SectionTitle from "../../../../../../components/SectionTitle/SectionTitle";
import ProductGroupLogo from "../../../../../../components/ProductGroupLogo/ProductGroupLogo";

interface ProductGroupSelectProps {
  onChange: (value: string) => void;
  productGroup: string | null;
}
export default function ProductGroupSelect({
  onChange,
  productGroup,
}: ProductGroupSelectProps) {
  return (
    <>
      <SectionTitle
        title={"Groupe d'articles"}
        className={styles["section-title"]}
      />
      <div className={styles["product-group-select"]}>
        {PRODUCT_GROUP.map((pg, index) => (
          <div
            className={clsx(styles["product-group"], {
              [styles["is-selected"]]: productGroup === pg,
            })}
            key={index}
            onClick={() => handleOnProductGroupSelect(pg)}
          >
            <ProductGroupLogo className={styles["logo"]} productGroup={pg} />
            <p>{translate(pg)}</p>
          </div>
        ))}
      </div>
    </>
  );

  function handleOnProductGroupSelect(productGroup: string) {
    onChange(productGroup);
  }
}
