import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";
import { useQuery } from "react-query";
import { getTracker } from "requests/tracker";
import { COLLECTED, PAYED, trackerStateMap } from "utils/tracking.utils";
import styles from "./linkTrackerToDropOff.module.scss";
import getStatusColor from "utils/translation/statusColor";
import { translateTrackingState } from "utils/tracking.init";
import Button from "components/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import { ModalContainer } from "components/modals/ModalContainer";
import QuoteSummaryModal from "./QuoteSummaryModal/QuoteSummaryModal";
import CenterCell from "components/CenterCell/CenterCell";

interface LinkTrackerToDropOffProps {
  trackId: string;
  onClose: () => void;
}

export default function LinkTrackerToDropOff({
  trackId,
  onClose,
}: LinkTrackerToDropOffProps) {
  const navigate = useNavigate();
  const [openQuoteSummaryModal, setOpenQuoteSummaryModal] =
    useState<boolean>(false);
  const { data: tracker, isLoading } = useQuery(
    ["tracker", trackId],
    () => getTracker(trackId),
    {
      refetchOnWindowFocus: false,
    }
  );

  if (isLoading) {
    return (
      <div className={styles["loading-section"]}>
        <PictureLoadingSpinner />
      </div>
    );
  }

  if (!tracker) {
    return <>tracker is missing !</>;
  }

  if (trackerStateMap.get(tracker.state)) {
    if (trackerStateMap.get(tracker.state)! >= COLLECTED) {
      return null;
    }
  }

  return (
    <tr className={styles["link-tracker-to-drop-off"]}>
      <td className={styles["date"]}>
        {!!tracker.eventsList[0].date
          ? new Date(tracker.eventsList[0].date).toLocaleDateString("fr-FR")
          : "indisponible"}
      </td>
      <td>
        <CenterCell>
          <div
            className={styles["state"]}
            style={{ color: getStatusColor(tracker.state) }}
          >
            {translateTrackingState(tracker.state)}
          </div>
        </CenterCell>
      </td>
      <td>
        <CenterCell>
          <Button
            className={styles["more-details"]}
            onClick={handleOpenQuoteSummaryModal}
          >
            plus de details
          </Button>
        </CenterCell>
      </td>
      <td>
        <CenterCell>
          <Button onClick={backToOrder}>Reprendre cette commande</Button>
        </CenterCell>
      </td>
      <ModalContainer
        isOpen={openQuoteSummaryModal}
        onCancel={handleCancelQuoteSummaryModal}
        width="narrow"
        height="fit"
      >
        <QuoteSummaryModal quoteId={tracker.quote.documentId} />
      </ModalContainer>
    </tr>
  );

  function handleCancelQuoteSummaryModal() {
    setOpenQuoteSummaryModal(false);
  }

  function handleOpenQuoteSummaryModal() {
    setOpenQuoteSummaryModal(true);
  }

  function backToOrder() {
    navigate(`/quick-access/new-in-store-drop-off/${trackId}`);
    onClose();
  }
}
