import {
  DropOffForm,
  DropOffProductForm,
  ShippingServiceForm,
} from "types/logistic.types";
import styles from "./productFormSection.module.scss";
import ProductGroupSelect from "pages/quickAccess/incomingShipping/cartToLogistic/productSection/ProductGroupSelect/ProductGroupSelect";
import { OPERATION_GROUP, PRODUCT_GROUP } from "utils/logistic.init";
import OperationGroupSelect from "pages/quickAccess/incomingShipping/cartToLogistic/productSection/OperationGroupSelect/OperationGroupSelect";
import WorkshopIdSection from "./WorkshopIdSection/WorkshopIdSection";
import ProductDescriptionSection from "../ProductDescriptionSection/ProductDescriptionSection";
import ImageSection from "../ImageSection/ImageSection";

interface ProductFormSectionProps {
  dropOff: DropOffForm;
  product: DropOffProductForm | null;
  onProductChange: (product: DropOffProductForm) => void;
  onDropOffChange: (dropOff: DropOffForm) => void;
}

export default function ProductFormSection({
  dropOff,
  product,
  onProductChange,
  onDropOffChange,
}: ProductFormSectionProps) {
  // useEffect(() => {
  //   resetOperationGroup();
  // }, [product?.productGroup]);

  if (!product || !product.selected) {
    return null;
  }

  return (
    <div className={styles["product-form-section"]}>
      <ProductGroupSelect
        onChange={handleProductGroupChange}
        productGroup={product.productGroup}
      />
      {PRODUCT_GROUP.includes(product.productGroup || "") && (
        <OperationGroupSelect
          onChange={handleOperationGroupChange}
          operationGroup={product.operationGroup}
          operationGroupList={OPERATION_GROUP.filter((op) =>
            op.includes(product.productGroup || "")
          )}
        />
      )}
      <WorkshopIdSection
        product={product}
        onWorkshopIdChange={handleWorkshopIdChange}
      />
      <ImageSection product={product} onProductChange={onProductChange} />
      <ProductDescriptionSection
        product={product}
        onProductChange={onProductChange}
      />
    </div>
  );

  function handleProductGroupChange(productGroup: string) {
    onProductChange({ ...product!, productGroup });
  }

  function handleOperationGroupChange(operationGroup: string) {
    onProductChange({ ...product!, operationGroup });
  }

  function handleWorkshopIdChange(workshopId: string | null) {
    onProductChange({ ...product!, workshopId });
  }

  function resetOperationGroup() {
    onProductChange({ ...product!, operationGroup: null });
  }
}
