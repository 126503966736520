import clsx from "clsx";
import Price from "../../../../../../components/Price/Price";
import {
  IOperation,
  Refashion,
} from "../../../../../../types/accounting.types";
import styles from "./quoteOperation.module.scss";
import RefashionSection from "./RefashionSelect/RefashionSelect";

interface QuoteOperationProps {
  operation: IOperation;
  onOperationChange?: (operation: IOperation) => void;
}

export default function QuoteOperation({
  operation,
  onOperationChange,
}: QuoteOperationProps) {
  return (
    <>
      <li
        className={clsx(
          styles["operation"],
          operation.canceled && styles["canceled"]
        )}
      >
        <p className={styles["description"]}>{operation.description}</p>
        <div className={styles["dotted"]}></div>
        <Price price={operation.price} className={styles["price"]} />
      </li>
      {onOperationChange && (
        <RefashionSection
          className={styles["refashion-section"]}
          operation={operation}
          onRefashionChange={handleRefashionSelect}
        />
      )}
    </>
  );

  function handleRefashionSelect(refashion: Refashion) {
    const { refashionProductCategory, refashionOperation, refashionDiscount } =
      refashion;

    onOperationChange &&
      onOperationChange({
        ...operation,
        refashionProductCategory,
        refashionOperation,
        refashionDiscount,
      });
  }
}
