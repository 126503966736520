import clsx from "clsx";
import styles from "./addOperationButton.module.scss";
import { AddCircleOutline } from "@mui/icons-material";

interface AddOperationButtonProps {
  className?: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}
export default function AddOperationButton({
  className,
  onClick,
}: AddOperationButtonProps) {
  return (
    <button
      className={clsx(
        styles["operation-entry"],
        styles["add-operation-button"]
      )}
      onClick={onClick}
    >
      <AddCircleOutline className={styles["add-icon"]} />
      Nouvelle opération
    </button>
  );
}
