import Widget from "../Widget";
import { LocalShippingOutlined } from "@mui/icons-material";
import styles from "./shopDrop.module.scss";
import Icon from "components/Icon/Icon";
import shopIcon from "assets/icons/shoemaker-shop.png";

export default function ShopDrop() {
  return (
    <Widget to={"/quick-access/new-in-store-drop-off"}>
      <div className={styles["icon-container"]}>
        <Icon logo={shopIcon} className={styles["icon"]} />
      </div>
      <p>Dépôt boutique</p>
    </Widget>
  );
}
