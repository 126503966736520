import { DropOffForm, IContact } from "types/logistic.types";
import styles from "./dropOffContactModal.module.scss";
import ContactForm from "components/forms/ContactForm/ContactForm";
import { INITIAL_CONTACT } from "utils/shipping.init";
import CustomerTrackersList from "./CustomerTrackersList/CustomerTrackersList";
import SectionTitle from "components/SectionTitle/SectionTitle";

interface DropOffContactModalProps {
  dropOff: DropOffForm;
  onDropOffChange: (dropOff: DropOffForm) => void;
  onClose: () => void;
}

export default function DropOffContactModal({
  dropOff,
  onDropOffChange,
  onClose,
}: DropOffContactModalProps) {
  return (
    <div className={styles["contact-modal"]}>
      <SectionTitle title="Informations contact" />
      <ContactForm
        className={styles["contact-form"]}
        contact={dropOff.contact || INITIAL_CONTACT}
        setContact={handleContactChange}
        detailedForm={false}
      />
      <CustomerTrackersList
        onClose={onClose}
        customerId={dropOff.contact?.id}
      />
    </div>
  );

  function handleContactChange(contact: IContact) {
    onDropOffChange({ ...dropOff, contact });
  }
}
