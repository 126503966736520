import { Outlet, RouteObject } from "react-router-dom";
import { authloader } from "../../routes";
import ErrorPage from "../errorPage/error-page";
import Overview from "./index";
import IncomingShipping from "./incomingShipping/incomingShipping";
import CartToLogistic from "./incomingShipping/cartToLogistic/cartToLogistic";
import OperationControl from "./qualityControl/QualityControl";
import ReadProduct from "./readProduct/readProduct";
import ProShipping from "./proShipping/proShipping";
import ProIncomingShipping from "./proShipping/proIncomingShipping/proIncomingShipping";
import ProOutgoingShipping from "./proShipping/proOutgoingShipping/proOutgoingShipping";
import CreateChronopost from "./createChronopost/createChronopost";
import Climbing from "./workshop/climbing/Climbing";
import Workshop from "./workshop/workshop";
import Cobbler from "./workshop/cobbler/Cobbler";
import Leather from "./workshop/leather/Leather";
import SearchComponent from "./searchComponent/SearchComponent";
import QuoteEditProvider from "./quoteEdit/QuoteEditProvider";
import InStoreDropOff from "./InStoreDropOff/InStoreDropOff";
import InStoreDropOffEdit from "./InStoreDropOff/InStoreDropOffEdit/InStoreDropOffEdit";
import InStoreDropOffQuote from "./quoteEdit/InStoreDropOffQuote";
import InStoreDropOffGenerate from "./InStoreDropOff/InStoreDropOffGenerate/InStoreDropOffGenerate";
import InStoreCollect from "./InStoreCollect/InStoreCollect";
import NewInStoreDropOff from "./newInStoreDropOff/NewInStoreDropOff";
import NewInStoreDropOffEdit from "./newInStoreDropOff/NewInStoreDropOffEdit";

export const route: RouteObject = {
  path: "/quick-access",
  element: <Outlet />,
  errorElement: <ErrorPage />,
  loader: authloader,
  children: [
    {
      index: true,
      element: <Overview />,
      loader: authloader,
    },
    {
      path: "incoming-shipping",
      element: <IncomingShipping />,
      loader: authloader,
    },
    {
      path: "collect-product",
      element: <InStoreCollect />,
      loader: authloader,
    },
    {
      path: "in-store-drop-off",
      element: <InStoreDropOff />,
      loader: authloader,
    },
    {
      path: "new-in-store-drop-off",
      element: <NewInStoreDropOff />,
      loader: authloader,
    },
    {
      path: "new-in-store-drop-off/:trackId",
      element: <NewInStoreDropOffEdit />,
      loader: authloader,
    },
    {
      path: "in-store-drop-off/:trackId",
      element: <InStoreDropOffEdit />,
      loader: authloader,
    },
    {
      path: "in-store-drop-off/generate/:trackId",
      element: <InStoreDropOffGenerate />,
      loader: authloader,
    },
    {
      path: "in-store-drop-off/quote-edit/:trackId",
      element: <InStoreDropOffQuote />,
      loader: authloader,
    },
    {
      path: "pro-incoming-shipping/:shopId",
      element: <ProIncomingShipping />,
      loader: authloader,
    },
    {
      path: "pro-outgoing-shipping/:shopId",
      element: <ProOutgoingShipping />,
      loader: authloader,
    },
    {
      path: "move-product",
      element: (
        <SearchComponent title={"Déplacer des articles"} query={""} to={""} />
      ),
      loader: authloader,
    },
    {
      path: "cart-to-logistic/:trackId",
      element: <CartToLogistic />,
      loader: authloader,
    },
    {
      path: "quote-edit/:trackId",
      element: <QuoteEditProvider />,
      loader: authloader,
    },
    {
      path: "quality-control",
      element: (
        <SearchComponent
          title={"Contrôler un article"}
          query={"&productsList.status=WORKSHOP_COMPLETE"}
          to={"/quick-access/quality-control-section/"}
        />
      ),
      loader: authloader,
    },
    {
      path: "search-product",
      element: (
        <SearchComponent
          title={"Chercher un article"}
          query={"&status=LOGISTIC_WORKSHOP"}
          to={"/quick-access/search-product/"}
        />
      ),
      loader: authloader,
    },
    {
      path: "search-product/:productId",
      element: <ReadProduct />,
      loader: authloader,
    },
    {
      path: "quality-control-section/:productId",
      element: <OperationControl />,
      loader: authloader,
    },
    {
      path: "quote-edit-search",
      element: (
        <SearchComponent
          useTrackId={true}
          B2COnly={true}
          title={"Rédiger un devis"}
          query={""}
          to={"/quick-access/cart-to-logistic/"}
        />
      ),
      loader: authloader,
    },
    {
      path: "pro-shipping",
      element: <ProShipping />,
      loader: authloader,
    },
    {
      path: "create-chronopost",
      element: <CreateChronopost />,
      loader: authloader,
    },
    {
      path: "workshop/climbing",
      element: <Climbing />,
      loader: authloader,
    },
    {
      path: "workshop",
      element: <Workshop />,
      loader: authloader,
    },
    {
      path: "workshop/climbing",
      element: <Climbing />,
      loader: authloader,
    },
    {
      path: "workshop/cobbler",
      element: <Cobbler />,
      loader: authloader,
    },
    {
      path: "workshop/leather",
      element: <Leather />,
      loader: authloader,
    },
  ],
};
