import { DropOffForm } from "types/logistic.types";
import { IShippingPoint } from "types/shipping.types";
import { useState } from "react";
import DropOffSummarySection from "./DropOffSummarySection/DropOffSummarySection";
import AddShippingAddressSection from "./AddShippingAddressSection/AddShippingAddressSection";

interface ValidateDropOffModalProps {
  dropOff: DropOffForm;
  onDropOffChange: (dropOff: DropOffForm) => void;
  onClose: () => void;
}

export default function ValidateDropOffModal({
  dropOff,
  onDropOffChange,
  onClose,
}: ValidateDropOffModalProps) {
  const ADDRESS_FORM_STEP = 1;
  const SUMMARY_STEP = 2;

  const [step, setStep] = useState<number>(
    dropOff.shippingService.name !== "DROP-OR-COLLECT"
      ? ADDRESS_FORM_STEP
      : SUMMARY_STEP
  );

  switch (step) {
    case ADDRESS_FORM_STEP:
      return (
        <AddShippingAddressSection
          dropOff={dropOff}
          onShippingAddressChange={handleShippingAddressChange}
          validateAddress={validateAddress}
        />
      );
    case SUMMARY_STEP:
      return (
        <DropOffSummarySection
          dropOff={dropOff}
          onPrevious={
            dropOff.shippingService.name !== "DROP-OR-COLLECT"
              ? () => setStep(ADDRESS_FORM_STEP)
              : undefined
          }
          onClose={onClose}
          onDropOffChange={onDropOffChange}
        />
      );

    default:
      return (
        <DropOffSummarySection
          dropOff={dropOff}
          onClose={onClose}
          onDropOffChange={onDropOffChange}
        />
      );
  }

  function validateAddress() {
    setStep(SUMMARY_STEP);
  }

  function handleShippingAddressChange(address: IShippingPoint) {
    onDropOffChange({ ...dropOff, shippingAddress: address });
  }
}
