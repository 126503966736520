import Button from "components/Button/Button";
import styles from "./newOrderModal.module.scss";

interface NewOrderModalProps {
  onCloseModal: () => void;
  onReset: () => void;
}

export default function NewOrderModal({
  onCloseModal,
  onReset,
}: NewOrderModalProps) {
  return (
    <div className={styles["new-order-modal"]}>
      <h3>Une commande est déjà en cours.</h3>
      <div className={styles["new-order-modal-bottom"]}>
        <Button className={styles["button"]} onClick={onCloseModal}>
          Reprendre
        </Button>
        <Button onClick={onReset}>Nouvelle commande</Button>
      </div>
    </div>
  );
}
