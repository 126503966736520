import { DropOffForm } from "types/logistic.types";
import styles from "./dropOffSummarySection.module.scss";
import DropOffShippingSummary from "./DropOffShippingSummary/DropOffShippingSummary";
import DropOffQuoteSummary from "./DropOffQuoteSummary/DropOffQuoteSummary";
import DropOffActionSection from "./DropOffActionSection/DropOffActionSection";
import DropOffContactSummary from "./DropOffContactSummary/DropOffContactSummary";

interface DropOffSummarySectionProps {
  dropOff: DropOffForm;
  onPrevious?: () => void | null;
  onClose: () => void;
  onDropOffChange: (dropOff: DropOffForm) => void;
}

export default function DropOffSummarySection({
  dropOff,
  onPrevious,
  onClose,
  onDropOffChange,
}: DropOffSummarySectionProps) {
  return (
    <div className={styles["validate-drop-off-modal"]}>
      <div className={styles["body"]}>
        <h3 className={styles["modal-title"]}>Récapitulatif de la commande</h3>
        <DropOffContactSummary dropOff={dropOff} />
        <DropOffShippingSummary dropOff={dropOff} />
        <DropOffQuoteSummary
          dropOff={dropOff}
          onDropOffChange={onDropOffChange}
        />
      </div>
      <DropOffActionSection
        dropOff={dropOff}
        onClose={onClose}
        onDropOffChange={onDropOffChange}
        onPrevious={onPrevious}
      />
    </div>
  );
}
