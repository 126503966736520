import PictureLoadingSpinner from "components/loadingSpinner/pictureloadingSpinner";
import styles from "./customerTrackersList.module.scss";
import { useQuery } from "react-query";
import { fetchCustomer } from "requests/customer";
import DataError from "components/errors/DataError/DataError";
import LinkTrackerToDropOff from "./LinkTrackerToDropOff/LinkTrackerToDropOff";
import SectionTitle from "components/SectionTitle/SectionTitle";

interface CustomerTrackersListProps {
  customerId?: string;
  onClose: () => void;
}

export default function CustomerTrackersList({
  customerId,
  onClose,
}: CustomerTrackersListProps) {
  const { data, isLoading } = useQuery(
    ["customer-tracker-list", customerId],
    () => fetchCustomer(customerId),
    {
      enabled: Boolean(customerId),
    }
  );

  if (!customerId) {
    return null;
  }

  if (isLoading) {
    return (
      <div className={styles["loading-section"]}>
        <PictureLoadingSpinner />
      </div>
    );
  }

  if (!data) {
    return (
      <div className={styles["customer-trackers-list"]}>
        <DataError error="Customer not found" />
      </div>
    );
  }

  const { trackers } = data;

  if (!trackers) {
    return (
      <div className={styles["customer-trackers-list"]}>
        <p>aucune commande disponible</p>
      </div>
    );
  }

  return (
    <>
      <SectionTitle
        title="Liste des commandes en cours"
        className={styles["section-title"]}
      />
      <div className={styles["customer-trackers-list"]}>
        <table className={styles["trackers-table"]}>
          <tbody className={styles["trackers-list"]}>
            {trackers.map((tracker, index) => (
              <LinkTrackerToDropOff
                key={index}
                trackId={tracker}
                onClose={onClose}
              />
            ))}
          </tbody>
        </table>
      </div>
    </>
  );
}
