import styles from "./validateFormModal.module.scss";
import { DropOffForm } from "types/logistic.types";

interface ValidateFormModalProps {
  dropOff: DropOffForm;
}

export default function ValidateFormModal({ dropOff }: ValidateFormModalProps) {
  return (
    <div className={styles["validate-form-modal"]}>
      <h3>Informations manquantes</h3>
      <ul className={styles["validate-form-list"]}>
        {!dropOff.contact && <li>Informations contact</li>}
        {!dropOff.repareDays && <li>Délai de réparation</li>}
        {/* {dropOff.shippingService.name !== "DROP-OR-COLLECT" &&
          !dropOff.shippingAddress && <li>Adresse de livraison</li>} */}
        {!dropOff.timeSlot && <li>Créneau manquant</li>}
        {dropOff.productList.map((p, index) => (
          <div key={index}>
            {!p.productGroup && (
              <li>{`Groupe d'articles pour l'article ${index + 1}`}</li>
            )}
            {!p.operationGroup && (
              <li>{`Groupe d'opérations pour l'article ${index + 1}`}</li>
            )}
            {!p.workshopId && (
              <li>{`Ticket atelier manquant pour l'article ${index + 1}`}</li>
            )}
            {p.operations.length < 1 && (
              <li>{`Ajouter au moins une opération pour l'article ${
                index + 1
              }`}</li>
            )}
          </div>
        ))}
        {!dropOff.productList.length && !dropOff.crossSells.length && (
          <li>Aucun article ni soins/entretien.</li>
        )}
      </ul>
    </div>
  );
}
