import { DropOffForm, DropOffProductForm } from "types/logistic.types";
import styles from "./newInStoreDropOff.module.scss";
import { useEffect, useMemo, useState } from "react";
import { INITIAL_DROPOFF, INITIAL_DROPOFF_PRODUCT } from "utils/logistic.init";
import clsx from "clsx";
import { ICrossSellProduct, IOperation } from "types/accounting.types";
import { OPERATION_DEFAULT } from "utils/operation.init";
import ProductListSection from "./ProductListSection/ProductListSection";
import { generateRandomString } from "utils/utils";
import ProductFormSection from "./ProductFormSection/ProductFormSection";
import { DEFAULT_CROSSSELL } from "utils/accounting.init";
import CrossSellSection from "./CrossSellSection/CrossSellSection";
import OperationSection from "./OperationSection/OperationSection";
import useLocalStorage from "hooks/useLocalStorage";
import PriceSummarySection from "./PriceSummarySection/PriceSummarySection";
import AddOperationAndCrossSellSection from "./AddOperationAndCrossSellSection/AddOperationAndCrossSellSection";
import ActionSection from "./ActionSection/ActionSection";
import { ModalContainer } from "components/modals/ModalContainer";
import NewOrderModal from "./NewOrderModal/NewOrderModal";
import ShippingSection from "./ShippingSection/ShippingSection";
import { deleteFile } from "requests/file";
import { useMutation } from "react-query";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "utils/toast.options";

interface NewInStoreDropOffProps {
  defaultDropOff?: DropOffForm;
}

export default function NewInStoreDropOff({
  defaultDropOff,
}: NewInStoreDropOffProps) {
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [dropOffForm, setDropOffForm] = useLocalStorage<DropOffForm>(
    "drop-off",
    defaultDropOff || INITIAL_DROPOFF
  ) as [DropOffForm, React.Dispatch<DropOffForm>];
  const deleteImageMutation = useMutation(deleteFile, {
    onError() {
      toast.error(
        "Erreur lors de la suppression de l'image.",
        TOAST_ERROR_OPTIONS
      );
    },
  });

  useEffect(() => {
    if (!defaultDropOff) {
      handleResumeOrder();
    } else {
      setDropOffForm(defaultDropOff);
    }
  }, []);

  const selectedProduct = useMemo(() => {
    return dropOffForm.productList.find((p) => p.selected) || null;
  }, [dropOffForm.productList]);

  return (
    <div className={styles["in-store-drop-off-container"]}>
      <div className={styles["in-store-drop-off"]}>
        <div className={styles["in-store-drop-off-left"]}>
          <ProductListSection
            onAddProduct={handleAddProduct}
            productList={dropOffForm.productList}
            onDeleteProduct={handleDeleteProduct}
            onSelectProduct={handleSelectProduct}
          />
          <ProductFormSection
            dropOff={dropOffForm}
            product={selectedProduct}
            onProductChange={handleProductChange}
            onDropOffChange={handleDropOffChange}
          />
        </div>
        <div className={styles["in-store-drop-off-right"]}>
          <PriceSummarySection
            dropOffForm={dropOffForm}
            selectedProduct={selectedProduct}
          />
          <div className={clsx(styles["operation-form"])}>
            <AddOperationAndCrossSellSection
              dropOff={dropOffForm}
              product={selectedProduct}
              onAddOperation={handleAddOperation}
              onAddCrossSell={handleAddCrossSell}
            />
            <OperationSection
              product={selectedProduct}
              onUpdateOperation={handleUpdateOperation}
              onDeleteOperation={handleDeleteOperation}
            />
            <CrossSellSection
              onChange={handleUpdateCrossSell}
              onDelete={handleDeleteCrossSell}
              crossSellsList={dropOffForm.crossSells}
            />
          </div>
        </div>
      </div>
      <ShippingSection
        dropOff={dropOffForm}
        onDropOffChange={handleDropOffChange}
      />
      <ActionSection
        dropOff={dropOffForm}
        onDropOffChange={handleDropOffChange}
      />
      <ModalContainer
        width="narrow"
        height="fit"
        isOpen={openModal}
        onCancel={handleCloseModal}
      >
        <NewOrderModal onCloseModal={handleCloseModal} onReset={handleReset} />
      </ModalContainer>
    </div>
  );

  function handleResumeOrder() {
    const form = window.localStorage.getItem("drop-off");
    if (form) {
      const parsedForm: DropOffForm = JSON.parse(form);
      if (
        parsedForm.contact === null &&
        parsedForm.crossSells.length === 0 &&
        parsedForm.shippingService.name === "DROP-OR-COLLECT" &&
        parsedForm.shippingService.price === 0
        // parsedForm.repareDays === null
      ) {
        if (
          (parsedForm.productList.length == 1 &&
            parsedForm.productList[0].operationGroup === null &&
            parsedForm.productList[0].productGroup === null) ||
          parsedForm.productList.length == 0
        ) {
          return;
        }
      }
      setOpenModal(true);
    }
  }

  async function handleReset() {
    for (let product of dropOffForm.productList) {
      for (let image of product.incomingPicturesList) {
        deleteImageMutation.mutate(image);
      }
    }
    setDropOffForm(INITIAL_DROPOFF);
    setOpenModal(false);
  }

  function handleCloseModal() {
    setOpenModal(false);
  }

  function handleAddCrossSell(crossSell?: ICrossSellProduct) {
    if (
      crossSell &&
      dropOffForm.crossSells.find((cs) => cs.slug == crossSell.slug)
    ) {
      return;
    }
    setDropOffForm({
      ...dropOffForm,
      crossSells: [
        ...(dropOffForm.crossSells || []),
        { ...(crossSell || DEFAULT_CROSSSELL), cartQuantity: 1 },
      ],
    });
  }

  function handleUpdateCrossSell(
    crossSell: ICrossSellProduct,
    targetIndex: number
  ) {
    setDropOffForm({
      ...dropOffForm,
      crossSells: (dropOffForm.crossSells || []).map((cs, sourceIndex) =>
        sourceIndex === targetIndex ? crossSell : cs
      ),
    });
  }

  function handleDeleteCrossSell(index: number) {
    setDropOffForm({
      ...dropOffForm,
      crossSells: (dropOffForm.crossSells || []).filter((_, i) => i !== index),
    });
  }

  function handleAddProduct() {
    function generateUniqueProductId(productList: DropOffProductForm[]) {
      let id = generateRandomString(24);
      while (productList.some((p) => p.id == id)) {
        id = generateRandomString(24);
      }
      return id;
    }
    const productId = generateUniqueProductId(dropOffForm.productList);
    const unSelectAll = dropOffForm.productList.map((p) => ({
      ...p,
      selected: false,
    }));
    setDropOffForm({
      ...dropOffForm,
      productList: [
        ...unSelectAll,
        { ...INITIAL_DROPOFF_PRODUCT, id: productId, selected: true },
      ],
    });
  }

  function handleDeleteProduct(product: DropOffProductForm) {
    setDropOffForm({
      ...dropOffForm,
      productList: dropOffForm.productList.filter((p) => p.id !== product.id),
    });
  }

  function handleSelectProduct(targetProduct: DropOffProductForm) {
    const unSelectAll = dropOffForm.productList.map((p) => ({
      ...p,
      selected: false,
    }));
    setDropOffForm({
      ...dropOffForm,
      productList: unSelectAll.map((p) =>
        p.id === targetProduct.id ? { ...targetProduct, selected: true } : p
      ),
    });
  }

  function handleProductChange(targetProduct: DropOffProductForm) {
    setDropOffForm({
      ...dropOffForm,
      productList: dropOffForm.productList.map((p) =>
        targetProduct.id === p.id ? targetProduct : p
      ),
    });
  }

  function handleDropOffChange(dropOff: DropOffForm) {
    setDropOffForm(dropOff);
  }

  function handleUpdateOperation(operation: IOperation, targetIndex: number) {
    if (
      !selectedProduct ||
      !selectedProduct.operationGroup ||
      !selectedProduct.productGroup
    ) {
      return;
    }
    setDropOffForm({
      ...dropOffForm,
      productList: dropOffForm.productList.map((p) => {
        if (p.id === selectedProduct.id) {
          return {
            ...p,
            operations: (p.operations || []).map((op, sourceIndex) =>
              sourceIndex === targetIndex ? operation : op
            ),
          };
        }
        return p;
      }),
    });
  }

  function handleDeleteOperation(targetIndex: number) {
    if (
      !selectedProduct ||
      !selectedProduct.operationGroup ||
      !selectedProduct.productGroup
    ) {
      return;
    }
    setDropOffForm({
      ...dropOffForm,
      productList: dropOffForm.productList.map((p) => {
        if (p.id === selectedProduct.id) {
          return {
            ...p,
            operations: (p.operations || []).filter(
              (_, sourceIndex) => sourceIndex !== targetIndex
            ),
          };
        }
        return p;
      }),
    });
  }

  function handleAddOperation(operation: IOperation) {
    if (
      !selectedProduct ||
      !selectedProduct.operationGroup ||
      !selectedProduct.productGroup
    ) {
      return;
    }
    if (operation.slug) {
      const found = selectedProduct.operations.findIndex(
        (op) => op.slug == operation.slug
      );
      if (found !== -1) {
        return handleDeleteOperation(found);
      } else {
        return setDropOffForm({
          ...dropOffForm,
          productList: dropOffForm.productList.map((p) => {
            if (p.id === selectedProduct.id) {
              return {
                ...p,
                operations: [...(p.operations || []), operation],
              };
            }
            return p;
          }),
        });
      }
    }
    handleAddOperationEmptyOperation();
  }

  function handleAddOperationEmptyOperation() {
    if (
      !selectedProduct ||
      !selectedProduct.operationGroup ||
      !selectedProduct.productGroup
    ) {
      return;
    }
    const newOperation = {
      ...OPERATION_DEFAULT,
      operationGroup: selectedProduct.operationGroup,
      productGroup: selectedProduct.productGroup,
      operationType:
        selectedProduct.productGroup === "SHOES"
          ? "SHOES_AUTRE"
          : "LEATHER_AUTRE",
    };
    setDropOffForm({
      ...dropOffForm,
      productList: dropOffForm.productList.map((p) => {
        if (p.id === selectedProduct.id) {
          return {
            ...p,
            operations: [...(p.operations || []), newOperation],
          };
        }
        return p;
      }),
    });
  }
}
