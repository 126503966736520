import WorkshopId from "components/workshopId/workshopId";
import styles from "./productListSection.module.scss";
import { AddCircleOutline, Delete } from "@mui/icons-material";
import { DropOffProductForm } from "types/logistic.types";
import clsx from "clsx";
import OperationGroupLogo from "components/OperationGroupLogo/OperationGroupLogo";

interface ProductListSectionProps {
  productList: DropOffProductForm[];
  onAddProduct: () => void;
  onDeleteProduct: (product: DropOffProductForm) => void;
  onSelectProduct: (product: DropOffProductForm) => void;
}

export default function ProductListSection({
  productList,
  onAddProduct,
  onDeleteProduct,
  onSelectProduct,
}: ProductListSectionProps) {
  return (
    <div className={styles["product-list"]}>
      <div className={styles["add-new-product"]} onClick={onAddProduct}>
        <AddCircleOutline className={styles["add-new-product-icon"]} />
      </div>
      {productList.map((product, index) => (
        <div
          key={index}
          className={clsx(
            styles["product"],
            product.selected && styles["selected"]
          )}
          onClick={() => onSelectProduct(product)}
        >
          <WorkshopId
            workshopId={product.workshopId || undefined}
            size="xxx-small"
          />
          <OperationGroupLogo
            className={styles["product-group-icon"]}
            operationGroup={product.operationGroup}
          />
          {product.selected && (
            <div
              className={styles["delete"]}
              onClick={(e) => {
                e.stopPropagation();
                onDeleteProduct(product);
              }}
            >
              <Delete />
            </div>
          )}
        </div>
      ))}
    </div>
  );
}
