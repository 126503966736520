import style from "./miscellaneousInfoForm.module.scss";
import { IProduct } from "../../../../types/logistic.types";
import Input from "../../../inputs/Input/Input";
import ColorPicker from "../../../ColorPicker/ColorPicker";

interface MiscellaneousInfoFormProps {
  productForm: IProduct;
  setProductForm: (product: IProduct) => void;
}

export default function MiscellaneousInfoForm({
  productForm,
  setProductForm,
}: MiscellaneousInfoFormProps) {
  return (
    <div className={style["miscellaneous-info-form"]}>
      <div className={style["left"]}>Autres informations</div>
      <div className={style["right"]}>
        <div className={style["input-line"]}>
          <Input
            className={style["input-container"]}
            label="Taille"
            name="size"
            type="text"
            value={productForm.size}
            onChange={({ target }) =>
              setProductForm({ ...productForm, size: target.value })
            }
          />
          <div style={{ width: "100%" }}>
            <label>Couleur *</label>
            <ColorPicker
              color={productForm.color}
              onColorChange={(value) =>
                setProductForm({ ...productForm, color: value })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
