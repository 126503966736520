import ShopDrop from "components/widget/ShopDrop/ShopDrop";
import IncomingShippingWidget from "../../components/widget/incomingShippingWidget/incomingShippingWidget";
import ProShipping from "../../components/widget/outgoingShipping/proShipping";
import styles from "./index.module.scss";
import WorkshopWidget from "components/widget/Workshop/workshop";
import SendEstimate from "components/widget/SendEstimate/SendEstimate";

export default function QuickAccess() {
  return (
    <div className={styles["quick-access"]}>
      <div className={styles["quick-access-grid"]}>
        {/* <SearchProductWidget /> */}

        <ShopDrop />
        <IncomingShippingWidget />

        {/* <LogisticWidget /> */}
        {/* <QualityControl /> */}
        <WorkshopWidget />
        {/* <QuoteWidget /> */}
        <ProShipping />
        <SendEstimate />
        {/* <ChronopostWidget /> */}
      </div>
    </div>
  );
}
