import SectionTitle from "components/SectionTitle/SectionTitle";
import ShippingServiceLogo from "components/ShippingServiceLogo/ShippingServiceLogo";
import { DropOffForm } from "types/logistic.types";
import styles from "./dropOffShippingSummary.module.scss";
import { addBusinessDays } from "utils/utils";
import { useSearchParams } from "react-router-dom";

interface DropOffShippingSummaryProps {
  dropOff: DropOffForm;
}

export default function DropOffShippingSummary({
  dropOff,
}: DropOffShippingSummaryProps) {
  const [searchParams] = useSearchParams();
  const nonDropOff = (searchParams.get("type") || "") === "non-drop-off";

  if (nonDropOff) {
    return <></>;
  }

  return (
    <div>
      <SectionTitle title="Livraison" />
      <div className={styles["shipping-summary"]}>
        <ShippingServiceLogo shippingService={dropOff.shippingService.name} />
        <p className={styles["time-slot"]}>
          {dropOff.repareDays
            ? addBusinessDays(dropOff.repareDays).toLocaleDateString("fr-FR", {
                day: "2-digit",
                weekday: "short",
                month: "short",
              })
            : "-"}
        </p>
        <p className={styles["time-slot"]}>
          {dropOff.timeSlot
            ? `${dropOff.timeSlot.start} - ${dropOff.timeSlot.end}`
            : "-"}
        </p>
        {dropOff.shippingService.name !== "DROP-OR-COLLECT" && (
          <div className={styles["shipping-address"]}>
            {dropOff.shippingAddress
              ? dropOff.shippingAddress?.address1! +
                " " +
                dropOff.shippingAddress?.address2! +
                " " +
                dropOff.shippingAddress?.zipCode! +
                ", " +
                dropOff.shippingAddress?.city
              : "indisponible"}
          </div>
        )}
      </div>
    </div>
  );
}
