import { DropOffForm, DropOffProductForm } from "types/logistic.types";
import styles from "./dropOffQuoteSummary.module.scss";
import SectionTitle from "components/SectionTitle/SectionTitle";
import DropOffProductsSummary from "../DropOffProductsSummary/DropOffProductsSummary";
import DropOffCrossSellSummary from "../DropOffCrossSellSummary/DropOffCrossSellSummary";
import QuoteSumUp from "pages/overview/read/_components/QuoteSection/QuoteSumUp/QuoteSumUp";
import translate from "utils/translation";

interface DropOffQuoteSummaryProps {
  dropOff: DropOffForm;
  onDropOffChange: (dropOff: DropOffForm) => void;
}

export default function DropOffQuoteSummary({
  dropOff,
  onDropOffChange,
}: DropOffQuoteSummaryProps) {
  return (
    <>
      <SectionTitle title="Devis" />
      <ul className={styles["product-list"]}>
        {dropOff?.productList?.map((product, i) => (
          <DropOffProductsSummary
            product={product}
            key={i}
            onProductChange={(product) => handleProductChange(product, i)}
          />
        ))}
      </ul>
      <ul className={styles["product-list"]}>
        {dropOff?.crossSells?.map((crossSell, i) => (
          <DropOffCrossSellSummary crossSell={crossSell} key={i} />
        ))}
      </ul>
      <QuoteSumUp
        className={styles["sub-total"]}
        title={"Sous-total"}
        value={totalOperations() + totalCrossSell()}
      />
      <QuoteSumUp
        className={styles["sub-total"]}
        title={`Livraison (${translate(dropOff.shippingService.name)})`}
        value={dropOff.shippingService.price || 0}
      />
      {!!totalRefashion() && (
        <QuoteSumUp
          className={styles["sub-total"]}
          title={`Total reFashion`}
          value={totalRefashion()}
        />
      )}
      <QuoteSumUp
        title={"Total"}
        value={totalDropOff()}
        className={styles["total"]}
      />
    </>
  );

  function totalOperations() {
    return (dropOff?.productList || []).reduce((acc, product) => {
      return (
        acc +
        product.operations.reduce(
          (acc, operation) => acc + (operation.price || 0),
          0
        )
      );
    }, 0);
  }

  function totalCrossSell() {
    return (dropOff?.crossSells || []).reduce(
      (acc, cs) => acc + (cs.price || 0) * (cs.cartQuantity || 1),
      0
    );
  }

  function totalRefashion() {
    let total = (dropOff?.productList || []).reduce((accProd, curProd) => {
      return (
        accProd +
        curProd.operations.reduce((accOp, curOp) => {
          return accOp + (curOp.refashionDiscount || 0);
        }, 0)
      );
    }, 0);
    return total;
  }

  function totalDropOff() {
    return (
      totalOperations() +
      totalCrossSell() +
      (dropOff.shippingService.price || 0)
    );
  }

  function handleProductChange(product: DropOffProductForm, index: number) {
    onDropOffChange({
      ...dropOff,
      productList: dropOff.productList.map((p, i) =>
        index === i ? product : p
      ),
    });
  }
}
