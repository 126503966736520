import clsx from "clsx";
import styles from "./addOperationAndCrossSellSection.module.scss";
import Button from "components/Button/Button";
import { AddCircleOutline } from "@mui/icons-material";
import { useMemo, useState } from "react";
import { ModalContainer } from "components/modals/ModalContainer";
import { ICrossSellProduct, IOperation } from "types/accounting.types";
import AddOperationModal from "./AddOperationModal/AddOperationModal";
import { DropOffForm, DropOffProductForm } from "types/logistic.types";
import AddCrossSellModal from "./AddCrossSellModal/AddCrossSellModal";

interface AddOperationAndCrossSellSectionProps {
  dropOff: DropOffForm;
  product: DropOffProductForm | null;
  onAddOperation: (operation: IOperation) => void;
  onAddCrossSell: (crossSell?: ICrossSellProduct) => void;
}

export default function AddOperationAndCrossSellSection({
  dropOff,
  product,
  onAddOperation,
  onAddCrossSell,
}: AddOperationAndCrossSellSectionProps) {
  const [openOperationModal, setOpenOperationModal] = useState<boolean>(false);
  const [openCrossSellModal, setOpenCrossSellModal] = useState<boolean>(false);

  const addOperationIsDisabled = useMemo(() => {
    return (
      dropOff.productList.length === 0 ||
      !product?.operationGroup ||
      !product?.productGroup
    );
  }, [product, dropOff.productList]);

  return (
    <div className={styles["add-section"]}>
      <AddButton
        disabled={addOperationIsDisabled}
        name={"Nouvelle opération"}
        onClick={handleAddOperation}
      />
      <AddButton name={"Soins et entretien"} onClick={handleAddCrossSell} />
      <ModalContainer
        width="narrow"
        isOpen={openOperationModal}
        onCancel={closeOperationModal}
      >
        <AddOperationModal
          product={product}
          onAddOperation={onAddOperation}
          onCancel={closeOperationModal}
        />
      </ModalContainer>
      <ModalContainer
        width="narrow"
        isOpen={openCrossSellModal}
        onCancel={closeCrossSellModal}
      >
        <AddCrossSellModal
          dropOff={dropOff}
          onAddCrossSell={onAddCrossSell}
          onCancel={closeCrossSellModal}
        />
      </ModalContainer>
    </div>
  );

  function closeOperationModal() {
    setOpenOperationModal(false);
  }

  function closeCrossSellModal() {
    setOpenCrossSellModal(false);
  }

  function handleAddOperation() {
    if (!product?.productGroup || !product?.operationGroup) {
      return;
    }
    setOpenOperationModal(true);
  }

  function handleAddCrossSell() {
    setOpenCrossSellModal(true);
  }
}

interface AddButtonProps {
  className?: string;
  name: string;
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  disabled?: boolean;
}

function AddButton({
  className,
  disabled = false,
  name,
  onClick,
}: AddButtonProps) {
  return (
    <Button
      className={clsx(
        styles["add-button"],
        disabled && styles["disabled"],
        className
      )}
      onClick={onClick}
    >
      <AddCircleOutline className={styles["add-icon"]} />
      {name}
    </Button>
  );
}
