import {
  IOperation,
  OperationForm,
  Refashion,
} from "../types/accounting.types";

export const OPERATION_DEFAULT: IOperation = {
  description: "",
  slug: "",
  price: 0,
  productGroup: "GENERIC_PRODUCT",
  operationGroup: "GENERIC_OPERATION",
  operationType: "SHOES_AUTRE",
};

export const OPERATION_INIT: OperationForm = {
  description: "",
  slug: "",
  price: 0,
  productGroup: "GENERIC_PRODUCT",
  operationGroup: "GENERIC_OPERATION",
  operationType: "SHOES_AUTRE",
  name: "",
  basePrice: 0,
  basePriceNoTax: 0,
  priceNoTax: 0,
  priceProBase: 0,
  priceProBaseNoTax: 0,
  priceProLuxe: 0,
  priceProLuxeNoTax: 0,
};

export const REFASHION_PRODUCT_CATEGORIES = [
  "ESCARPINS_TALON_HAUT",
  "SANDALES_TALON_HAUT",
  "BOTTES_BOTTINES_TALON_HAUT",
  "BOTTES_BOTTINES_TALON_BAS",
  "SANDALES_PLATE_BASSE",
  "SNEAKERS_PLATE_BASSE",
  "VILLE_PLATE_BASSE",
];

export const REFASHION_OPERATIONS = [
  "FERMETURE_ECLAIR_INF_20_CM",
  "FERMETURE_ECLAIR_SUP_20_CM",
  "BONBOUT",
  "PATIN",
  "RESSEMELAGE_GOMME",
  "RESSEMELAGE_CUIR",
  "COUTURE_COLLAGE",
];

export const OPERATION_TYPE = [
  "SHOES_MISE_EN_FORME",
  "SHOES_AUTRE",
  "SHOES_PATAFER",
  "SHOES_COLLAGE_COUTURE",
  "SHOES_RESSEMELAGE",
  "SHOES_ENTRETIEN",
  "SHOES_TEINTURE",
  "CLIMBING_RESSEMELAGE",
  "CLIMBING_ENROBAGE",
  "LEATHER_ENTRETIEN",
  "LEATHER_TEINTURE",
  "LEATHER_TRANCHE",
  "LEATHER_DOUBLURE",
  "LEATHER_JONC",
  "LEATHER_COUTURE",
  "LEATHER_ENCHAPE",
  "LEATHER_POIGNEE",
  "LEATHER_BANDOULIERE",
  "LEATHER_AUTRE",
  "LEATHER_FAG",
];

export const OPERATION_TYPE_BY_GROUP = {
  LEATHER_LARGE: [
    "LEATHER_ENTRETIEN",
    "LEATHER_TEINTURE",
    "LEATHER_TRANCHE",
    "LEATHER_DOUBLURE",
    "LEATHER_JONC",
    "LEATHER_COUTURE",
    "LEATHER_ENCHAPE",
    "LEATHER_POIGNEE",
    "LEATHER_BANDOULIERE",
    "LEATHER_AUTRE",
    "LEATHER_FAG",
  ],
  SHOES_MAN: [
    "SHOES_MISE_EN_FORME",
    "SHOES_PATAFER",
    "SHOES_COLLAGE_COUTURE",
    "SHOES_RESSEMELAGE",
    "SHOES_ENTRETIEN",
    "SHOES_TEINTURE",
    "SHOES_AUTRE",
  ],
  SHOES_WOMAN: [
    "SHOES_MISE_EN_FORME",
    "SHOES_PATAFER",
    "SHOES_COLLAGE_COUTURE",
    "SHOES_RESSEMELAGE",
    "SHOES_ENTRETIEN",
    "SHOES_TEINTURE",
    "SHOES_AUTRE",
  ],
  SHOES_SNEAKERS: [
    "SHOES_MISE_EN_FORME",
    "SHOES_PATAFER",
    "SHOES_COLLAGE_COUTURE",
    "SHOES_RESSEMELAGE",
    "SHOES_ENTRETIEN",
    "SHOES_TEINTURE",
    "SHOES_AUTRE",
  ],
  SHOES_CLIMBING: ["CLIMBING_RESSEMELAGE", "CLIMBING_ENROBAGE"],
};

export const REFASHION_OPERATIONS_PRICE: Refashion[] = [
  {
    refashionOperation: "FERMETURE_ECLAIR_INF_20_CM",
    refashionDiscount: 1000,
    refashionProductCategory: "FAG < 20 cm",
  },
  {
    refashionOperation: "FERMETURE_ECLAIR_SUP_20_CM",
    refashionDiscount: 1400,
    refashionProductCategory: "FAG > 20 cm",
  },
  {
    refashionOperation: "BONBOUT",
    refashionDiscount: 700,
    refashionProductCategory: "Talons / Bonbouts",
  },
  {
    refashionOperation: "PATIN",
    refashionDiscount: 800,
    refashionProductCategory: "Patins",
  },
  {
    refashionOperation: "RESSEMELAGE_GOMME",
    refashionDiscount: 1800,
    refashionProductCategory: "Ressemelage Gomme",
  },
  {
    refashionOperation: "RESSEMELAGE_CUIR",
    refashionDiscount: 2500,
    refashionProductCategory: "Ressemelage Cuir",
  },
  {
    refashionOperation: "COUTURE_COLLAGE",
    refashionDiscount: 800,
    refashionProductCategory: "Couture / Collage",
  },
];

export const PRODUCT_CATEGORIES = [
  { value: "ESCARPINS_TALON_HAUT", description: "Escarpins à talons" },
  { value: "SANDALES_TALON_HAUT", description: "Sandales à talons" },
  { value: "BOTTES_BOTTINES_TALON_HAUT", description: "Bottes à talons" },
  { value: "BOTTES_BOTTINES_TALON_BAS", description: "Bottes" },
  { value: "SANDALES_PLATE_BASSE", description: "Sandales" },
  { value: "SNEAKERS_PLATE_BASSE", description: "Sneakers" },
  { value: "VILLE_PLATE_BASSE", description: "Ville" },
  { value: "VESTE_CUIR", description: "Veste" },
  { value: "SAC_MAIN", description: "Sac à main" },
  { value: "VALISE", description: "Valise" },
  { value: "CORDO_AUTRE", description: "Cordonnerie" },
  { value: "MARO_AUTRE", description: "Maroquinerie" },
];
