import SectionTitle from "components/SectionTitle/SectionTitle";
import styles from "./imageSection.module.scss";
import { DropOffProductForm } from "types/logistic.types";
import ImagesForm from "components/forms/imagesForm/imagesForm";
import { AddCircleOutlineOutlined } from "@mui/icons-material";
import ImagePreview from "components/forms/AddImage/ImagePreview/ImagePreview";
import { useRef } from "react";
import { useMutation } from "react-query";
import { deleteFile, streamFileMutation } from "requests/file";
import { toast } from "react-toastify";
import { TOAST_ERROR_OPTIONS } from "utils/toast.options";
import { generateRandomString } from "utils/utils";
import Button from "components/Button/Button";

interface ImageSectionProps {
  product: DropOffProductForm | null;
  onProductChange: (product: DropOffProductForm) => void;
}

export default function ImageSection({
  product,
  onProductChange,
}: ImageSectionProps) {
  const hiddenFileInput = useRef<HTMLInputElement>(null);
  const { isLoading, mutateAsync: addFileAsync } = useMutation(
    streamFileMutation,
    {
      onSuccess(data) {
        if (!product) {
          return;
        }
        onProductChange({
          ...product,
          incomingPicturesList: [...product.incomingPicturesList, data.message],
        });
      },
      onError() {
        toast.error("Erreur lors de l'ajout de l'image.", TOAST_ERROR_OPTIONS);
      },
    }
  );

  const { mutate: deleteImage } = useMutation(deleteFile, {
    onSuccess(data) {
      if (!product) {
        return;
      }
      onProductChange({
        ...product,
        incomingPicturesList: product.incomingPicturesList.filter(
          (image) => image !== data
        ),
      });
    },
    onError() {
      toast.error(
        "Erreur lors de la suppression de l'image.",
        TOAST_ERROR_OPTIONS
      );
    },
  });

  if (!product || !product.workshopId) {
    return null;
  }

  return (
    <>
      <SectionTitle
        title={"Images de l'article (optionnel)"}
        className={styles["section-title"]}
      />
      <div className={styles["add-image-form"]}>
        <div className={styles["add-image"]} onClick={handleAddClick}>
          <AddCircleOutlineOutlined className={styles["icon"]} />
          {/* Image */}
        </div>
        <ImagePreview
          isLoading={isLoading}
          imagesList={product.incomingPicturesList}
          onDeleteImage={handleDeleteImage}
        />
      </div>
      <input
        ref={hiddenFileInput}
        className={styles["file-input"]}
        type="file"
        onChange={handleFileChange}
        multiple
      />
    </>
  );

  function handleDeleteImage(filename: string) {
    deleteImage(filename);
  }

  function handleAddClick() {
    if (isLoading) {
      return;
    }
    hiddenFileInput?.current?.click();
  }

  async function handleFileChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (!e.target.files) {
      return;
    }
    // if (!multiple) {
    if (e.target.files && e.target.files[0]) {
      await addFileAsync({
        filename: `-${product?.id}-${generateRandomString(
          6
        )}-dropOff-logistic.`,
        fileContent: e.target.files[0],
      });
    }
    return;
  }
}
