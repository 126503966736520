import styles from "./cobbler.module.scss";
import Widget from "../Widget";
import CobblerIcon from "components/icons/CobblerIcon";

export default function CobblerWidget() {
  return (
    <Widget to={"cobbler"}>
      <div className={styles["icon-container"]}>
        <CobblerIcon className={styles["icon"]} />
      </div>
      <div>Cordonnerie</div>
    </Widget>
  );
}
