import Widget from "../Widget";
import styles from "./climbing.module.scss";
import ClimbingIcon from "components/icons/ClimbingIcon";

export default function ClimbingWidget() {
  return (
    <Widget to={"climbing"}>
      <div className={styles["icon-container"]}>
        <ClimbingIcon className={styles["icon"]} />
      </div>
      <div>Chaussons d'escalades</div>
    </Widget>
  );
}
