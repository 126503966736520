import CobblerIcon from "components/icons/CobblerIcon";
import LeatherIcon from "components/icons/LeatherIcon";

interface ProductGroupLogoProps {
  className?: string;
  productGroup: string;
}

export default function ProductGroupLogo({
  className,
  productGroup,
}: ProductGroupLogoProps) {
  switch (productGroup) {
    case "SHOES":
      return <CobblerIcon className={className} />;
    case "LEATHER":
      return <LeatherIcon className={className} />;
    default:
      return <></>;
  }
}
